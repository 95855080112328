<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>이메일 발송 내역</h1>
            <div class="is-right">
            </div>
        </div>
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="55px">
                    <col width="250px">
                    <col width="80px">
                    <col width="100px">
                    <col width="80px">
                    <col width="160px">
                    <col>
                </colgroup>
                <tr>
                    <th><label>발송일자</label></th>
                    <td>
                        <div class="jh-cols">                        
                            <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                                    maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="startDate"></v-date-picker>
                            </v-menu>
                            <span class="jh-unit">~</span>                       
                            <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                                    maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="endDate"></v-date-picker>
                            </v-menu>
                        </div>
                    </td>
                    <th><label>고객번호</label></th>
                    <td>
                        <input type="text" class="jh-form" v-model="searchValue.CUST_NO" @keypress.enter="search">
                    </td>
                    <th><label>수신이메일</label></th>
                    <td>
                        <input type="text" class="jh-form" v-model="searchValue.RECP_EML_ADDR" @keypress.enter="search">
                    </td>
                    <td class="has-search">
                        <v-btn class="jh-btn is-search" @click="search">조회</v-btn>
                    </td>
                </tr>
            </table>
        </div>
        <div class="jh-cols">
            <div class="jh-form-wrap is-pt-10">
                <div class="jh-ui-header">
                    <h2>이메일 발송 리스트</h2>
                    <div class="is-right">
                    </div>
                </div>
                <data-tables
                    ref="dataTable"
                    :data-table-options="dataTableOptions"
                    @click:row="onClickRow"
                    :paginationOptions="paginationOptions"
                    v-model="selectedRows"
                />
            </div>
            <div class="jh-form-wrap is-pt-10 is-col-fix" style="width: 40%;">
                <div class="jh-ui-header">
                    <h2>이메일 발송 상세정보</h2>
                    <div class="is-right">  
                    </div>
                </div>
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="80px">
                        <col>
                        <col width="80px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label>고객번호</label></th>
                            <td>
                                <span class="is-value">{{ emailHistoryDetail.RECP_CUST_NO }}</span>
                            </td>
                            <th><label>고객명</label></th>
                            <td>
                                <span class="is-value">{{ emailHistoryDetail.RECP_NM }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th><label>이메일</label></th>
                            <td colspan="3">
                                <span class="is-value">{{ emailHistoryDetail.RECP_EML_ADDR }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th><label>제목</label></th>
                            <td colspan="3">
                                <span class="is-value">{{ emailHistoryDetail.TITLE }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="jh-editor is-mt-5"><ckeditor v-if="lodash.includes($options.name, active_tab_id) || isPopup" v-model="emailHistoryDetail.CONTENT" :config="editorConfig" :read-only="true"></ckeditor></div>
            </div>                    
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

export default {
    name: "MENU_E030202", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
        isPopup: {
            type: Boolean,
            default: false
        }
    },
    components: {
        dataTables
    },
    data() {
        return {
            lodash: _,

            menuStartDate: false,
            menuEndDate: false,
            from: moment().format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
            dates: [
                moment().format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD")
            ],

            searchValue: {
                CUST_NO: null,
                RECP_EML_ADDR: null
            },

            dataTableOptions: {
                fixedHeader: true,
                headers: [
                    { text: "순번", value: "ROWNUM", align: "center", width: "50px" },
                    { text: "발송일자", value: "REQ_DT", align: "center", width: "90px" },
                    { text: "발송시간", value: "REQ_TM",  align: "center", width: "70px", sortable: false },
                    { text: "제목", value: "TITLE", },
                    { text: "이메일", value: "RECP_EML_ADDR",  align: "center", width: "200px" },
                    { text: "발송직원", value: "SEND_NM",  align: "center", width: "120px" },
                    { text: "발송결과", value: "RSLT",  align: "center", width: "90px" },
                ],
                height: "615px",
                hideDefaultFooter: true,
                itemKey: "ID",
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,

                load: this.getList
            },
            paginationOptions: {
                totalVisible: 10
            },

            editorConfig: {
                extraPlugins: "font, colorbutton, colordialog, openlink, spacingsliders",
                height: "490px",
                openlink_enableReadOnly: true,
                openlink_modifier: 0,
                toolbar: [
                    // { name: "clipboard", items: [ "Cut", "Copy", "Paste", "-", "Undo", "Redo" ] },//, "PasteText", "PasteFromWord"
                    // { name: "editing" }, //, items: [ "Scayt" ]
                    { name: "links", items: [ "Link", "Unlink" ] },//, "Anchor"
                    { name: "insert", items: [ "Image", "Table", "HorizontalRule", "SpecialChar" ] },
                    { name: "forms" },
                    { name: "styles", items: [ "Font", "FontSize" ] },
                    { name: "styles", items: [ "Styles", "Format" ] },
                    { name: "others", items: [ "-" ] },
                    "/",
                    { name: "colors", items: [ "TextColor", "BGColor", "spacingsliders" ] },
                    { name: "basicstyles", items: [ "Bold", "Italic", "Strike" ] }, //, "-", "RemoveFormat"
                    { name: "paragraph", items: [ "NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote" ] },
                    { name: "document", items: [ "Source" ] },
                    { name: "tools", items: [ "Maximize" ] },
                    { name: "about", items: [] }
                ],
                toolbarCanCollapse:  true,
                toolbarStartupExpanded: false,
            },

            requestData: {
                headers: {
                    SERVICE: "message.channel.email",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            },

            selectedKey: null,
            selectedRows: [],

            emailHistoryDetail: {
                ID: null,
                REQ_DT: null,
                REQ_TM: null,
                RECP_CUST_NO: null,
                RECP_NM: null,
                TITLE: null,
                RECP_EML_ADDR: null,
                RTN_EML_ADDR: null,
                SEND_NM: null,
                RESULT: null,
                CONTENT: null
            }
        };
    },

    computed: {
        ...mapGetters({
            active_tab_id: "commonStore/GE_COMMON_ACTIVE_TAB_ID",
        }),
        dataTableInstance: function() {
            return this.$refs.dataTable;
        },
    },
    methods: {
        init: async function() {
            // 헤더 초기화 및 세팅
            this.setDataTableParams();
        },
        setDataTableParams: function() {
            // header 세팅
            this.requestData.headers.URL = "/api/message/email/history/list";
            this.requestData.headers.METHOD = "list";
            this.requestData.headers.ASYNC = false;

            // sendData 세팅
            this.requestData.sendData.FROM = this.dates[0];
            this.requestData.sendData.TO = this.dates[1];
            this.requestData.sendData.CUST_NO = this.searchValue.CUST_NO;
            this.requestData.sendData.RECP_EML_ADDR = this.searchValue.RECP_EML_ADDR;
            // this.requestData.sendData.USER_ID = this.$store.getters["userStore/GE_USER_ROLE"].userId;
        },
        getList: async function(loadOptions) {
            // 페이징 정보 세팅
            this.requestData.headers.ROW_CNT = loadOptions.itemsPerPage;
            this.requestData.headers.PAGES_CNT = loadOptions.page;
            if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
                this.requestData.sendData.SORT_ORDR = loadOptions.sortBy[0];
            else this.requestData.sendData.SORT_ORDR = undefined;
            if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
                this.requestData.sendData.SORT_DRCT = loadOptions.sortDesc[0] ? "desc" : "asc";
            else this.requestData.sendData.SORT_DRCT = undefined;

            return this.common_postCall(this.requestData).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                _.each(data, (item) => {
                    item.TITLE = this.restoreXSS(item.TITLE);
                    item.CONTENT = this.restoreXSS_CKeditor(item.CONTENT);
                    item.REQ_TM = item.REQ_TM ? item.REQ_TM.substring(0, 8) : item.REQ_TM;
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT,
                };
            });
        },
        search: function() {
            if(!this.checkDate()) return false;
            this.setDataTableParams();

            if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
            else this.dataTableInstance.loadData();
        },
        checkDate() {
            if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(3, 'months').format('YYYY-MM-DD'))) {
                this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                return false;
            } 

            return true;
        },
        fromOnInput: function() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
                // 종료일자보다 이후를 입력했으면 종료일자로 변경
                if (moment(this.from).isAfter(this.dates[1])) {
                    this.$nextTick(() => {
                        this.from = this.dates[1];
                    });
                }

                this.$nextTick(() => {
                    this.dates[0] = this.from;
                    // datepick 다시 랜더링
                    this.menuStartDate = false;
                    this.menuStartDate = true;
                });
            }
        },
        startDate(e) {
            this.from = e;
        },
        toOnInput: function() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
                // 시작일자보다 이전을 입력했으면 시작일자로 변경
                if (moment(this.to).isBefore(this.dates[0])) {
                    this.$nextTick(() => {
                        this.to = this.dates[0];
                    });
                }
                
                this.$nextTick(() => {
                    this.dates[1] = this.to;
                    // datepick 다시 랜더링
                    this.menuEndDate = false;
                    this.menuEndDate = true;
                });
            }
        },
        endDate(e) {
            this.to = e;
        },

        onClickRow: function(item, row) { 
            if (this.selectedKey === item.ID) {
                row.select(false);
                this.selectedKey = null;
                this.emailHistoryDetail = {
                    ID: null,
                    REQ_DT: null,
                    REQ_TM: null,
                    RECP_CUST_NO: null,
                    RECP_NM: null,
                    TITLE: null,
                    RECP_EML_ADDR: null,
                    RTN_EML_ADDR: null,
                    SEND_NM: null,
                    RESULT: null,
                    CONTENT: null
                };
            }
            else {
                row.select(true);
                this.selectedKey = item.ID;
                this.emailHistoryDetail = Object.assign(this.emailHistoryDetail, item);
            }
        },
    },
    created() {},
    mounted() {
        this.init();
    },
    watch: {}
};
</script>

<style></style>